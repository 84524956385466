import React from 'react';

const About = () => {
    return (
        <section className="section about-section">
            <h1>About us</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestiae repudiandae architecto qui adipisci in officiis,
                aperiam sequi atque perferendis eos, autem maiores nisi saepe
                quisquam hic odio consectetur nobis veritatis quasi explicabo
                obcaecati doloremque?
            </p>
        </section>
    );
};

export default About;
